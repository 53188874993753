import Team2915U from "../../media/images/IMG_9410.JPG";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import Footer from "../footer";
import { Helmet } from "react-helmet-async";

const welcome_para =
  "Lynfield College Robotics, Team 2915 is a student-led Robotics group based in Auckland, New Zealand. We compete in the VEX Robotics Competition and the VEX IQ Challenge. We have several teams competing and succeeding in both competitions. During the week we go to robotics competitions called 'scrimmages'. In these scrimmages we compete against tons of teams around Auckland hoping to come out on top each week. We've won many awards including tournament champion both at national events and at the world stage in America. Lynfield College Robotics is known by many in the robotics comunitity. Overall there are 20,000+ VRC teams competing in over 50 different countries.";

// sponsors tagline
// const sponsor_tagline = "We thank our sponsors for helping us with the opportunity to do what we love ❤️"

// const spons_link_1 = "https://gasappliancespecialists.nz/"

const quickStats = [
  {
    stat: (new Date().getFullYear() - 2008).toString() + " Years",
    tagline: "in competitive Robotics, Since 08'",
  },
  {
    stat: "3 Consecutive",
    tagline: "Tournament Champions at VRC World championships",
  },
  {
    stat: "61 Awards",
    tagline: "on a national & international level",
  },
];

const teams = [
  {
    teamName: "2915B",
    teamMembers: "",
    isIQ: false,
  },
  {
    teamName: "2915C",
    teamMembers: "",
    isIQ: false,
  },
  {
    teamName: "2915E",
    teamMembers: "",
    isIQ: false,
  },
  {
    teamName: "2915F",
    teamMembers: "",
    isIQ: false,
  },
  {
    teamName: "2915U",
    teamMembers: "",
    isIQ: false,
  },
  {
    teamName: "2915X",
    teamMembers: "",
    isIQ: false,
  },
];

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Lynfield College Robotics</title>
        <meta
          name="description"
          content="The Home of New Zealand based x3 VEX Robotics World Champions. This is 2915, a High School VRC and VIQC team Lynfield College Robotics based in Auckland, New Zealand whom have been competing in High School Robotics since 2008."
        />
      </Helmet>

      <main className="max-w-screen-xl mx-auto">
        {/* hero */}
        <div className="h-screen relative flex items-center md:max-w-screen md:h-auto md:justify-center">
          <img
            className="h-full object-cover md:rounded-xl"
            src={Team2915U}
            alt=""
          />
          <div className="transition-all ease-in duration-75 absolute inset-0 bg-blue-600 mix-blend-multiply md:rounded-xl"></div>
          <div className="absolute mx-5 font-bold text-3xl backdrop-filter md:text-6xl">
          <h1>  
              <span className="text-white">This is</span>
              <br/>
              <span className="text-blue-50">Lynfield College Robotics</span>
            </h1>
            <div className="text-white text-base font-normal inline-flex items-center">
              <LocationMarkerIcon className="h-4" />
              <span>&nbsp;Auckland, New Zealand</span>
            </div>
            <div className="mt-2 md:mt-0">
              <a href="/about">
                <button
                  type="button"
                  className="transition-all ease-in duration-75 bg-white text-blue-600 hover:bg-blue-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 mb-3 md:px-6 md:py-3.5"
                >
                  Learn more
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* about section */}
        <section>
        <div className="mx-5 lg:mx-0">
        
          <h1 className="py-10 text-3xl font-bold">Welcome</h1>
          <p className="text-base md:text-left">{welcome_para}</p>
          

          
          <div className="grid grid-flow-row justify-between md:gap-x-8 md:grid-flow-col mt-16">
            {quickStats.map((stat) => {
              return (
                <>
                  <div className="mt-8 md:mt-0">
                    <span className="text-blue-600 font-bold text-2xl">
                      {stat.stat}
                    </span>
                    <p className="font-normal text-gray-700">{stat.tagline}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        </section>


        {/* teams section */}
        <section>
        <div className="mx-5 lg:mx-0 mb-10">
          <h2 className="py-10 text-3xl font-bold mt-5">Our Teams</h2>
          <div className="grid grid-flow-row gap-4 md:grid-cols-3">
            {teams.map((team) => {
              return (
                <div>
                  {team.isIQ ? (
                    <a
                      href={
                        "https://www.robotevents.com/teams/VIQC/" +
                        team.teamName
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="p-6 w-full bg-white rounded-lg border border-gray-200 hover:bg-gray-100">
                        <div className="flex mb-2 justify-between">
                          <span className="text-xl font-bold tracking-tight text-gray-900">
                            {team.teamName}
                          </span>
                          <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded self-center">
                            IQ
                          </span>
                        </div>
                      </div>
                    </a>
                  ) : (
                    <a
                      href={
                        "https://www.robotevents.com/teams/VRC/" + team.teamName
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="p-6 w-full bg-white rounded-lg border border-gray-200 hover:bg-gray-100">
                        <div className="flex mb-2 justify-between">
                          <span className="text-xl font-bold tracking-tight text-gray-900">
                            {team.teamName}
                          </span>
                          <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded self-center">
                            VRC
                          </span>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        </section>

        {/* sponsors section */}
        {/* 
        <section>
        <div className="mx-5 lg:mx-0">

                <h2 className="py-10 text-3xl font-bold mt-5">Sponsors</h2>

                <p className="text-justify md:text-left">{sponsor_tagline}</p>
                
                <div className="flex justify-center my-5">
                    <a href={spons_link_1} target="new">
                        <img src={GasApplianceLogo} alt="Gas Appliance Specialist logo"/>
                    </a>
                </div>
            </div> 
            </section>*/}
      </main>
      <Footer />
    </>
  );
}
