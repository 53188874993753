export default function button(props) {
  return (
    <>
      {/* header */}
      <a href={props.href} target={props.target}>
        <button className="relative inline-flex items-center justify-center p-0.5 mt-5 mb-2 mr-2 overflow-hidden w-full md:w-auto text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:ring-cyan-200">
          <span className="relative px-12 py-2.5 transition-all ease-in duration-75 bg-gray-50 w-full md:w-auto rounded-md group-hover:bg-opacity-0">
            {props.title}
          </span>
        </button>
      </a>
    </>
  );
}
