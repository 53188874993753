import facebook from "../media/images/fb.svg";
import youtube from "../media/images/yt.png";

const facebookUrl = "https://facebook.com/lynfieldrobotics";
const youtubeUrl = "https://youtube.com/@LynfieldRobotics";

const navigation = [
  { name: "Home", href: "/", isMenu: false },
  {
    name: "About",
    href: "/about",
    isMenu: true,
    childItems: [
      { name: "History", href: "/about/history" },
      // { name: "Teams", href: "/about#teams" },
      // { name: "Awards", href: "/about#awards" },
      { name: "Robotics", href: "/about/Robotics" },
    ],
  },
  // { name: "Media", href: "/media", isMenu: false },
  // {
  // 	name: "Resources",
  // 	href: "/resources",
  // 	isMenu: true,
  // 	childItems: [
  // 	{ name: "Programming", href: "#" },
  // 	{ name: "Build", href: "#" },
  // 	{ name: "Tips and Tricks", href: "#" },
  // 	],
  // },
  { name: "Sponsorship", href: "/Sponsorship", isMenu: false },
];

export default function Footer() {
  return (
    <>
      <div className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex justify-end md:justify-between items-center flex-row px-4 py-10">
            <div className="">
              <p className="font-semibold text-gray-900 text-lg">
                Lynfield College Robotics
                <br />
                <span className="text-right w-full block font-normal">
                  2915
                </span>
              </p>
              <div className="flex justify-end mt-2">
                <div className="inline-grid gap-5 grid-cols-2 justify-end">
                  <a href={facebookUrl} target="_blank" rel="noreferrer">
                    <img className="w-8" src={facebook} alt="Facebook logo" />
                  </a>
                  <a href={youtubeUrl} target="_blank" rel="noreferrer">
                    <img className="w-8" src={youtube} alt="Youtube Logo" />
                  </a>
                </div>
              </div>
            </div>

            <ul className="hidden md:grid grid-flow-row md:grid-flow-col gap-5">
              {navigation.map((item) => {
                if (item.isMenu) {
                  return (
                    <ul className="px-4">
                      <li className="font-semibold text-gray-900 hover:text-blue-600 transition duration-300 ease-in-out">
                        <a href={item.href}>{item.name}</a>
                      </li>
                      {item.childItems.map((childItem) => {
                        return (
                          <li className="py-2">
                            <a
                              href={childItem.href}
                              className="hover:text-blue-600 transition duration-300 ease-in-out"
                            >
                              {childItem.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  );
                } else {
                  return (
                    <li>
                      <a
                        href={item.href}
                        className="font-semibold text-gray-900 hover:text-blue-600 transition duration-300 ease-in-out"
                      >
                        {item.name}
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="">
            <hr />
            <p className="text-center py-6">
              &copy; 2008-{new Date().getFullYear()} Lynfield College Robotics.
              All rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
