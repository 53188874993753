import TemplateLayout from "../main_layout";
import Worlds2915 from "../../media/images/2915_2016_worlds.webp";
import { Helmet } from "react-helmet-async";

const blurb1 =
  "Based in Auckland, New Zealand, Lynfield College Robotics started off in 2008 when VEX was first introduced in New Zealand. Initially the group consisted of just a small handful of boys and girls tinkering around with electronics in a little garage but over the years we’ve grown from strength to strength and have proven ourselves strong contenders in the world of VEX. 2915 is an established team in New Zealand, known for our immense success on the national and international stage as winners of major accolades such as the Excellence Award, Robot Skills and Tournament Champions.  We also host several scrimmages throughout the season and have also been home to the VRC Nationals event in New Zealand a handful of times. ";
const past_Games =
  "2915 has been competing in high school robotics since 2008 and we have alot to show for it!";

// add prop.spacing

const GamesAward = [
  {
    isIQ: false,
    GameName: "Over Under",
    GameSeason: "2023-24",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards: "Tournament Champion x2, MS Excellence Award, Skils Champions",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "in Progress...",
    article: "14496915659543",
  },
  {
    isIQ: true,
    GameName: "Full Volume",
    GameSeason: "2023-24",
    Awards: "",
    NationalsChampsAttend: false,
    NationalChampsWin: false,
    NationalAwards: "",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "in Progress...",
    article: "14498919550359",
  },
  {
    isIQ: false,
    GameName: "Spin Up",
    GameSeason: "2022-23",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: false,
    NationalAwards: "Design Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "in Progress...",
    article: "9660529810455",
  },
  {
    isIQ: true,
    GameName: "Slapshot",
    GameSeason: "2022-23",
    NationalChampsWin: false,
    NationalsChampsAttend: false,
    NationalAwards: "Such Empty :(",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    spacing: true,
    article: "9762084197143",
  },

  {
    isIQ: false,
    GameName: "Tipping Point",
    GameSeason: "2021-22",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "Tournament Champions, Excellence Award, Amaze Award, Think Award, Kiwichallenge Champions",
    WorldChampsAttend: true,
    WorldChampsWin: false,
    WorldAwards: "None",
    article: "9660695622807",
  },
  {
    isIQ: true,
    GameName: "Pitching In",
    GameSeason: "2021-22",
    NationalChampsWin: false,
    NationalsChampsAttend: false,
    NationalAwards: "Such Empty :(",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    article: "9761737897623",
  },
  {
    isIQ: false,
    GameName: "Change Up",
    GameSeason: "2020-21",
    Awards: "",
    NationalsChampsAttend: false,
    NationalChampsWin: false,
    NationalAwards:
      "Tournament Champions, Excellence Award, Amaze Award, Think Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    article: "9660827298839",
  },
  {
    isIQ: true,
    GameName: "Rise Above",
    GameSeason: "2020-21",
    NationalChampsWin: true,
    NationalsChampsAttend: true,
    NationalAwards:
      "Teamwork Champion Award, Teamwork 2nd Place Award, Innovate Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    article: "9761334619543",
  },
  {
    isIQ: false,
    GameName: "Tower Takeover",
    GameSeason: "2019-20",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards: "Tournament Champions, Amaze Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "None",
    article: "9660927408407",
  },
  {
    isIQ: true,
    GameName: "Squared Away",
    GameSeason: "2019-20",
    NationalChampsWin: false,
    NationalsChampsAttend: true,
    NationalAwards: "Amaze Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    article: "9761179309079",
  },
  {
    isIQ: false,
    GameName: "Turning Point",
    GameSeason: "2018-19",
    Awards: "",
    NationalsChampsAttend: false,
    NationalChampsWin: false,
    NationalAwards: "Tournament Champions, Amaze Award",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "None",
    article: "9661009797527",
  },
  {
    isIQ: false,
    GameName: "In the Zone",
    GameSeason: "2017-18",
    Awards: "Promote Award",
    NationalsChampsAttend: false,
    NationalChampsWin: false,
    NationalAwards: "None",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "None",
    article: "9661170051351",
  },
  {
    isIQ: false,
    GameName: "Starstruck",
    GameSeason: "2016-17",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: false,
    NationalAwards: "Tournament Finalists",
    WorldChampsAttend: true,
    WorldChampsWin: false,
    WorldAwards: "None",
    article: "9661321161879",
  },
  {
    isIQ: false,
    GameName: "Nothing But Net",
    GameSeason: "2015-16",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "Tournament Champions, Robot Skills Champion, Excellence Award, Think Award, Division Champion, 2x Division Finalist",
    WorldChampsAttend: true,
    WorldChampsWin: true,
    WorldAwards: "Excellence Award, Robot Skills Champion",
    article: "9661371440151",
  },
  {
    isIQ: false,
    GameName: "Skyrise",
    GameSeason: "2014-15",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards: "Tournament Champions, Amaze Award, Robot Skills Champions",
    WorldChampsAttend: true,
    WorldChampsWin: true,
    WorldAwards:
      "World Tournament Champions, Science Division Champions, 2nd Place VRC Robot Skills Champions",
    article: "9661504917911",
  },
  {
    isIQ: false,
    GameName: "Toss Up",
    GameSeason: "2013-14",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "2x national champion, National skills champion, Lower north island regional champion",
    WorldChampsAttend: true,
    WorldChampsWin: true,
    WorldAwards: "World Tournament Champions, World skills finalist",
    article: "9661569769111",
  },
  {
    isIQ: false,
    GameName: "Sack Attack",
    GameSeason: "2012-13",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "Tournament Champion, Robot Skills Finalist, Asia-Pacific Semi-Finalist",
    WorldChampsAttend: true,
    WorldChampsWin: true,
    WorldAwards: "Tournament Champions, Robot Skills Champion",
    article: "9661809013015",
  },
  {
    isIQ: false,
    GameName: "Gateway",
    GameSeason: "2011-12",
    Awards: "Educate Award",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "Excellence Award, 3x Tournament Champion, Robot Skills Champion, Robot Skills Finalists, Programming Skills Finalist",
    WorldChampsAttend: true,
    WorldChampsWin: true,
    WorldAwards:
      "World Cup Finalist, Science Division Champions, Technology Division Finalists",
    article: "9661854749719",
  },
  {
    isIQ: false,
    GameName: "Round Up",
    GameSeason: "2010-11",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards:
      "Tournament Finalists, Auckland Regional Tournament Champions",
    WorldChampsAttend: true,
    WorldChampsWin: false,
    WorldAwards: "Division Finalists, Create Award",
    article: "9662040819351",
  },
  {
    isIQ: false,
    GameName: "Clean Sweep",
    GameSeason: "2009-10",
    Awards: "",
    NationalsChampsAttend: true,
    NationalChampsWin: true,
    NationalAwards: "Auckland Regional Tournament Champions",
    WorldChampsAttend: false,
    WorldChampsWin: false,
    WorldAwards: "",
    article: "9662226322583",
  },
];
export default function History() {
  return (
    <>
      <Helmet>
        <title>History | 2915</title>
        <meta
          name="description"
          content="Discover the rich history of Lynfield College Robotics. From our humble beginnings to our current successes, learn about our team's journey to becoming a competitive force in the world of robotics. Explore our archives, see our achievements, and learn about the challenges we've faced along the way."
        />
      </Helmet>

      <TemplateLayout title="History" tagline="The Humble Beginnings...">
        <section>
          <h2 className="text-3xl font-bold">
            Like every good story, it started in a garage.
          </h2>
          <p className="my-5">{blurb1}</p>
          <div className="flex justify-center">
            <img
              className="lg:w-1/2 rounded-md"
              src={Worlds2915}
              alt="2915 party on the world stage with the excellence award."
            />
          </div>
        </section>

        <section>
        <div className="">
          <h2 className="text-3xl font-bold mt-16">Past Games and awards</h2>
          <p className="my-5">{past_Games}</p>
        </div>
        <div className="grid grid-flow-row gap-4 md:grid-cols-1">
          {GamesAward.map((GamesAward) => {
            return (
              <div>
                {GamesAward.isIQ ? (
                  <a
                    href={
                      "https://viqc-kb.recf.org/hc/en-us/articles/" +
                      GamesAward.article
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="p-6 w-full bg-white rounded-lg border border-gray-200 hover:bg-gray-100">
                      <div>
                        <div className="flex mb-2 justify-between">
                          <div className="flex  mb-2 justify-self-start">
                            <h3 className="text-xl font-bold tracking-tight text-gray-900">
                              {GamesAward.GameName}
                            </h3>
                            {GamesAward.NationalChampsWin ? (
                              <img
                                className="h-7 mx-2 self-start"
                                src="https://em-content.zobj.net/thumbs/120/sony/336/flag-new-zealand_1f1f3-1f1ff.png"
                                title="Recived the Tournament Champion award at a National Level"
                                alt="Flag of New Zealand"
                              />
                            ) : null}
                            {GamesAward.WorldChampsWin ? (
                              <img
                                className="h-7 self-start"
                                src="https://em-content.zobj.net/thumbs/120/sony/336/flag-united-states_1f1fa-1f1f8.png"
                                title="Recived the Tournament Champion award at a International Level"
                                alt="Flag of the United States of Ameirca"
                              />
                            ) : null}
                          </div>
                          {GamesAward.isIQ ? (
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded self-center">
                              IQ
                            </span>
                          ) : (
                            <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded self-center">
                              VRC
                            </span>
                          )}
                        </div>

                        <h4 className="font-light">{GamesAward.GameSeason}</h4>

                        {GamesAward.NationalsChampsAttend ? (
                          <div className="pt-2">
                            <h5 className="font-medium">
                              National Championships:
                            </h5>
                            <p className="font-normal">
                              {GamesAward.NationalAwards}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="font-medium"></p>
                          </div>
                        )}
                        {GamesAward.WorldChampsAttend ? (
                          <div className="pt-2">
                            <h5 className="font-medium">
                              World Championships:
                            </h5>
                            <p className="font-normal">
                              {GamesAward.WorldAwards}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="font-medium"></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </a>
                ) : (
                  <a
                    href={
                      "https://vrc-kb.recf.org/hc/en-us/articles/" +
                      GamesAward.article
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="p-6 w-full bg-white rounded-lg border border-gray-200 hover:bg-gray-100">
                      <div>
                        <div className="flex mb-2 justify-between">
                          <div className="flex  mb-2 justify-self-start">
                            <h3 className="text-xl font-bold tracking-tight text-gray-900">
                              {GamesAward.GameName}
                            </h3>
                            {GamesAward.NationalChampsWin ? (
                              <img
                                className="h-7 mx-2 self-start"
                                src="https://em-content.zobj.net/thumbs/120/sony/336/flag-new-zealand_1f1f3-1f1ff.png"
                                title="Recived the Tournament Champion award at a National Level"
                                alt="Flag of New Zealand"
                              />
                            ) : null}
                            {GamesAward.WorldChampsWin ? (
                              <img
                                className="h-7 self-start"
                                src="https://em-content.zobj.net/thumbs/120/sony/336/flag-united-states_1f1fa-1f1f8.png"
                                title="Recived the Tournament Champion award at a International Level"
                                alt="Flag of the United States of Ameirca"
                              />
                            ) : null}
                          </div>
                          <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded self-center">
                            VRC
                          </span>
                        </div>

                        <h4 className="font-light">{GamesAward.GameSeason}</h4>

                        {GamesAward.NationalsChampsAttend ? (
                          <div className="pt-2">
                            <h5 className="font-medium">
                              National Championships:
                            </h5>
                            <p className="font-normal">
                              {GamesAward.NationalAwards}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="font-medium"></p>
                          </div>
                        )}
                        {GamesAward.WorldChampsAttend ? (
                          <div className="pt-2">
                            <h5 className="font-medium">
                              World Championships:
                            </h5>
                            <p className="font-normal">
                              {GamesAward.WorldAwards}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="font-medium"></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </a>
                )}
              </div>
            );
          })}
        </div>
        </section>
      </TemplateLayout>
    </>
  );
}
