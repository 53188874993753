import { Helmet } from "react-helmet-async";
import TemplateLayout from "../main_layout";
import REC1 from "../../media/images/2915U_Worlds.webp";
import REC2 from "../../media/images/VRC_Worlds.webp";
import Kiwi1 from "../../media/images/IQ_Rise_Above_Nats.webp";
import Kiwi2 from "../../media/images/Spin_Up_Nats_Awards.webp";
import TiP2915U from "../../media/images/TiP_Nats.webp";
import Button from "../button";
import vrcGame from "../../media/images/VRC-Over-Under.png";
import viqGame from "../../media/images/VIQRC-Full-Volume.png";

const intro =
  "VEX Robotics, the Robotics Education & Competition Foundation (RECF), and Kiwibots NZ are organizations that are focused on providing robotics education and competitions for students of all ages. VEX Robotics produces educational robotics kits and competition platforms for students, while the REC Foundation supports robotics education and competitions worldwide, including the VEX Robotics Competition. Kiwibots NZ provides VEX Robotics products and support to schools, educators, and students in New Zealand and organizes local VEX Robotics competitions and events. Together, these organizations form a collaborative and supportive robotics community that promotes innovation and inspires the next generation of engineers and technologists.";

const VEX_who =
  "VEX Robotics is a robotics competition platform that allows students to design, build, and program robots to compete in challenges that simulate real-world engineering problems. The VEX Robotics Competition (VRC) and the VEX IQ Challenge (VIQC) are two of the most popular VEX competitions, aimed at different age groups and skill levels. Both competitions involve teams of students who work together to design, build, and program robots to complete specific tasks within a set time limit. These competitions not only challenge students to use their technical skills, but also to develop teamwork, communication, and problem-solving skills, all while having fun and learning about robotics and engineering. VEX Robotics competitions are held at local, national, and international levels, providing students with the opportunity to compete against teams from all over the world.";
const VEX_MS =
  '"To inspire and enable future innovators by creating a hands-on learning environment that allows students to discover the excitement and rewards of science, technology, engineering, and mathematics (STEM) through the development of teamwork, critical thinking, and communication skills."';
const VEX_MS_blurb =
  "VEX  aims to provide students with a fun and engaging way to learn about STEM subjects, while also fostering important life skills such as collaboration, problem-solving, and leadership. Through their robotics competition platform, VEX Robotics seeks to empower the next generation of innovators and prepare them for success in the 21st century workforce.";

const REC_who =
  "The Robotics Education & Competition (REC) Foundation is a non-profit organization based in the United States that promotes robotics and STEM education through its various programs and competitions. The foundation works in partnership with VEX Robotics to organize and support the VEX Robotics Competition (VRC), the VEX IQ Challenge (VIQC), and other robotics competitions and programs for students around the world. The REC Foundation's mission is to inspire and educate students in STEM subjects, providing them with opportunities to develop critical thinking, problem-solving, and teamwork skills through hands-on robotics activities. The foundation also provides grants and resources to schools and organizations to help them establish and grow robotics programs in their communities.";
const REC_MS =
  "The Robotics Education & Competition Foundation’s global mission is to provide every educator with competition, education, and workforce readiness programs to increase student engagement in science, technology, engineering, math, and computer science.";

const KIWIBOTS_who =
  "Kiwibots is a New Zealand-based organization that focuses on promoting robotics and STEM education through its various programs and competitions. Kiwibots is an official partner of VEX Robotics and is responsible for organizing and supporting the VEX Robotics Competition (VRC) and the VEX IQ Challenge (VIQC) in New Zealand. These competitions provide students with opportunities to design, build, and program robots to complete specific tasks within a set time limit, challenging them to use their technical skills, creativity, and teamwork abilities. Kiwibots also offers training and resources for teachers and mentors, as well as grants to schools and organizations to help them establish and grow robotics programs. The organization's mission is to inspire and prepare the next generation of innovators and problem-solvers through robotics and STEM education, while also promoting values such as sportsmanship, inclusivity, and community engagement.";
const KIWIBOTS_MS =
  '"To inspire and prepare the next generation of innovators and problem-solvers by promoting robotics and STEM education in New Zealand."';
const KIWIBOTS_MS_blurb =
  "Kiwibots aims to provide students with opportunities to develop their technical skills, creativity, and teamwork abilities by organizing and supporting robotics competitions and programs such as the VEX Robotics Competition and the VEX IQ Challenge. The organization also seeks to provide teachers and mentors with the necessary training and resources to effectively teach robotics and STEM subjects, as well as to offer grants to schools and organizations to help establish and grow their robotics programs. Kiwibots' core values include inclusivity, sportsmanship, and community engagement.";

const VexQuickStats = [
  {
    stat: "20,000 Teams",
    tagline: "engaged in VEX competitions.",
  },
  {
    stat: "50 Countries",
    tagline: "involve around the world.",
  },
  {
    stat: "$50 Millon",
    tagline: "awarded for college sponsorships.",
  },
];

const RecQuickStats = [
  {
    stat: "2 Million",
    tagline: "students engaged in RECF competitions.",
  },
  {
    stat: (new Date().getFullYear() - 2006).toString() + " Years",
    tagline: "of competitions for students.",
  },
  {
    stat: "150,000 Volunteers",
    tagline: "registered across the world.",
  },
];

const kiwibotsQuickStats = [
  {
    stat: "200 Teams",
    tagline: "competing in VEX Robotics.",
  },
  {
    stat: (new Date().getFullYear() - 2009).toString() + " Years",
    tagline: "of competitions for students.",
  },
  {
    stat: "10,000 Kiwis",
    tagline: "particpating in STEM Education.",
  },
];

export default function VEX() {
  return (
    <>
      <Helmet>
        <title>Robotics | 2915</title>
        <meta
          name="description"
          content="Discover how partners like VEX Robotics, Kiwibots, and the REC Foundation, is shaping the future of high school robotics in New Zealand and the world. Learn about the programs and competitions we participate in, our innovative approaches to design and engineering, and how we foster teamwork and leadership among our members. Join us in our mission to inspire the next generation of STEM leaders and make a positive impact on our community and beyond."
        />
      </Helmet>

      <TemplateLayout
        title="About Robotics"
        tagline="The backbone of High School Robotics"
      >
        {/* VEX robotics*/}
        <section>
        <div className="">
          <div>
            <h2 className="text-3xl font-bold py-5">The Robotics Program</h2>
            <p className="mb-20">{intro}</p>
          </div>

          <div className="grid grid-cols-1 auto-rows-auto overflow-hidden md:grid-cols-2">
            <div>
              <h3 className="text-3xl font-bold py-5">Who are VEX?</h3>
              <p className="mb-5">{VEX_who}</p>
              <h3 className="text-3xl font-bold py-5">The VEX mission</h3>
              <p className="mb-5">{VEX_MS}</p>
              <p> {VEX_MS_blurb} </p>
              <Button
                title="Learn More"
                href="https://vexrobotics.com"
                target="_blank"
              />
            </div>
            <img
              src={TiP2915U}
              className="hidden rounded-md mx-10 h-fit  md:inline"
              alt="2915U in the finals at the VRC 2021-22 Nationals TiP event. Battling for possesion of a Netural Goal againsts 8757A"
            />
          </div>
          {/* Current Game */}
          <div>
            <h4 className="text-2xl font-bold pt-20 pb-5">
              2023-24 VEX Challanges
            </h4>
            <div className="grid my-5 grid-cols-1 justify-items-start items-end gap-10 md:grid-cols-2 ">
              <div className="">
                <img
                  className="h-48"
                  alt="VRC Game for 2023-24"
                  src={vrcGame}
                />
                <Button
                  title="Learn More"
                  href="https://www.vexrobotics.com/v5/competition/vrc-current-game"
                  target="_blank"
                />
              </div>
              <div className="">
                <img
                  className="h-36"
                  alt="VIQ Competition logo for 2023-24"
                  src={viqGame}
                />
                <Button
                  title="Learn More"
                  href="https://www.vexrobotics.com/iq/competition/viqc-current-game"
                  target="_blank"
                />
              </div>
            </div>
          </div>
          {/* quick stats */}
          <div className="grid grid-flow-row justify-between md:gap-x-8 md:grid-flow-col mt-16">
            {VexQuickStats.map((stat) => {
              return (
                <>
                  <div className="mt-8 md:mt-0">
                    <span className="text-blue-600 font-bold text-2xl">
                      {stat.stat}
                    </span>
                    <p className="font-normal text-gray-700">{stat.tagline}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        </section>

        {/* RECF */}
        <section>
        <div>
          <div>
            <h3 className="text-3xl font-bold mt-20 py-5">Who are RECF?</h3>
            <p className="mb-10">{REC_who}</p>
            <div className="h-auto overflow-hidden">
              <div className="flex basis-0 shrink gap-2 flex-wrap my-5 md:flex-nowrap md:justify-between md:h-80">
                <img
                  className="hidden rounded-md max-w-full object-contain md:block"
                  src={REC1}
                  alt="New Zealand at the Parade of Nations at VEX Worlds, with team 2915U."
                />
                <img
                  className="rounded-md max-w-full object-contain"
                  src={REC2}
                  alt="Inside the dome at VEX Worlds during the VRC JROT competition knockouts."
                />
              </div>
            </div>
            <h3 className="text-3xl font-bold py-5">The RECF mission</h3>
            <p className="mb-5">{REC_MS}</p>
            <p> {VEX_MS_blurb} </p>
            <Button
              title="Learn More"
              href="https://roboticseducation.org/"
              target="_blank"
            />
          </div>
          <div className="grid grid-flow-row justify-between md:gap-x-8 md:grid-flow-col mt-16">
            {RecQuickStats.map((stat) => {
              return (
                <>
                  <div className="mt-8 md:mt-0">
                    <span className="text-blue-600 font-bold text-2xl">
                      {stat.stat}
                    </span>
                    <p className="font-normal text-gray-700">{stat.tagline}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        </section>
        
        {/* Kiwibots */}
        <section>
        <div>
          <div>
            <h3 className="text-3xl font-bold mt-20 py-5">Who are Kiwibots?</h3>
            <p className="mb-10">{KIWIBOTS_who}</p>
            <div className="h-auto overflow-hidden">
              <div className="flex basis-0 shrink gap-2 flex-wrap my-5 md:flex-nowrap md:justify-between md:h-80">
                <img
                  className="rounded-md max-w-full object-contain"
                  src={Kiwi2}
                  alt="Award recipients at the VRC Nationals event by kiwibots for 2022-23 Spin Up"
                />
                <img
                  className="hidden rounded-md max-w-full object-contain md:block"
                  src={Kiwi1}
                  alt="Teamwork challange with 2915V at the VIQ Nationals event by kiwibots for 2020-21 Rise Above."
                />
              </div>
            </div>

            <h3 className="text-3xl font-bold py-5">The Kiwibots mission</h3>
            <p className="mb-5">{KIWIBOTS_MS}</p>
            <p>{KIWIBOTS_MS_blurb} </p>
            <Button
              title="Learn More"
              href="https://kiwibots.co.nz"
              target="_blank"
            />
          </div>
          <div className="grid grid-flow-row justify-between md:gap-x-8 md:grid-flow-col mt-16">
            {kiwibotsQuickStats.map((stat) => {
              return (
                <>
                  <div className="mt-8 md:mt-0">
                    <span className="text-blue-600 font-bold text-2xl">
                      {stat.stat}
                    </span>
                    <p className="font-normal text-gray-700">{stat.tagline}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        </section>
      </TemplateLayout>
    </>
  );
}
