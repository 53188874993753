import { Helmet } from "react-helmet-async";
import TemplateLayout from "../main_layout";
import Button from "../button";

import F_Pits from "../../media/images/2915F_VRC_Pits.webp";
import P_driver from "../../media/images/2915P_IQ_driver.webp";
import sponsor_pack from "../../media/sponsor_pack.pdf"

// import pdf from '../../media/images/a.pdf'
// import sponsor1 from '../../media/images/placeholder.png'

// const sponsorship_top_txt = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque scelerisque tincidunt mauris vel mollis. Vestibulum vel commodo ligula. Integer quis sem id ex pretium commodo nec vitae elit. Donec suscipit dui tincidunt felis fringilla, id viverra tortor pulvinar. Aliquam aliquam tellus ex, id tristique elit eleifend eu. Nam nec."
// const Sponsor1_para = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque scelerisque tincidunt mauris vel mollis. Vestibulum vel commodo ligula. Integer quis sem id ex pretium commodo nec vitae elit. Donec suscipit dui tincidunt felis fringilla, id viverra tortor pulvinar. Aliquam aliquam tellus ex, id tristique elit eleifend eu. Nam nec lectus felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
const Why_us_txt =
  "Although winning National and World titles ultimately boils down to hardwork and passion on our end, maintaining world class robots also requires a heavy supply of resources, the cost of which really adds up. Furthermore, to compete at international events such as VEX Worlds in the US we need to be able to send a whole team of students whose flights and accommodation don’t come cheap. Ultimately, sponsors help us cover these costs associated with robotics and make it possible for us to compete to the best of our ability.";
const sponsorship_outcome_txt =
  "By sponsoring Lynfield College Robotics your company will be seen as supporting youth in the STEM fields and helping spark a whole new generation of not just engineers but young adults entering the workplace with a plethora of important skills at their disposal from teamwork, communication, improvisation and time management. Furthermore, by sponsoring Lynfield College Robotics your organization’s logo will be actively displayed upon our robots, banners and uniform where possible for publicity. We can collaborate with your organization to run interactive demonstrations with team and our robots in action where you also get a hands on opportunity to engage with our robots and team.";
const intrested_sponsor_txt =
  "Being a sponsor of Lynfield College Robotics can cost you very little but help us go a long way literally! If you or your organisation is interested being a proud sponsor of Lynfield College Robotics, please view our sponsorship package to learn more!";

export default function Sponsorship() {
  return (
    <>
      <Helmet>
        <title>Sponsorship | 2915</title>
        <meta
          name="description"
          content="Discover the companies and organizations that support Lynfield College Robotics and our mission. Learn about our valued sponsors and their contributions to our team, from financial support to mentorship and access to cutting-edge technology. See how these partnerships help us to achieve our goals and develop our skills as robotics enthusiasts and future leaders in the field. Explore opportunities for sponsorship and find out how you can support our team and the broader robotics community."
        />
      </Helmet>

      <TemplateLayout title="Sponsorship" tagline="How we do what we 💙">
        <div>
          {/* <div className = "">
                    <h1 className="text-3xl font-bold mb-5">Our Sponsors</h1>
                    <p className="my-1">{sponsorship_top_txt}</p>
                </div> 


                {/* <h2 className="text-2xl font-bold mt-16 mb-5">Sponsor 1</h2>
                <div className="grid my-5 grid-cols-1 justify-items-center gap-10 md:grid-cols-2 ">
                    <a href={Sponsor1_btnlink}>
                    <img src={sponsor1} className="rounded-md"/>
                    </a>
                    <div className= "">    
                        <p className="my-5">{Sponsor1_para}</p>
                        <button className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden w-full md:w-auto text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white focus:ring-4 focus:ring-cyan-200">
                            <span className="relative px-12 py-2.5 transition-all ease-in duration-75 bg-gray-50 w-full md:w-auto rounded-md group-hover:bg-opacity-0">
                                <a href={Sponsor1_btnlink}>Learn More</a>
                            </span>
                        </button>
                    </div>
                </div> */}
        </div>

        <div className="mb-5">
          <h1 className="text-3xl font-bold mt- mb-5">Why Sponsor Us?</h1>
          <p className="my-1">{Why_us_txt}</p>
          <div className="h-auto overflow-hidden">
            <div className="flex basis-0 shrink gap-2 flex-wrap my-5 w-full md:flex-nowrap md:justify-between md:h-80 md:grow-0">
              <img
                className="rounded-md grow object-contain grow md:grow-0"
                src={F_Pits}
                alt="2915F working on the robot at the pits during TiP."
              />
              <img
                className="hidden rounded-md max-w-full object-contain md:block"
                src={P_driver}
                alt="2915P's drive team."
              />
            </div>
          </div>
        </div>
        <div className="">
          <h1 className="text-3xl font-bold mt-16 mb-5">
            Outcomes for Sponsors
          </h1>
          <p>{sponsorship_outcome_txt}</p>
        </div>

        <div className="">
          <h1 className="text-3xl font-bold mt-16 mb-5">
            Intrested in Sponsoring Us?
          </h1>
          <p> {intrested_sponsor_txt}</p>
          <Button
            title="Sponsorship Package"
            href={sponsor_pack}
            target="_blank"
          />
        </div>
      </TemplateLayout>
    </>
  );
}
