import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { cardsKeys, cards } from "./components/pages/Updates";
import TemplateLayout from "./components/main_layout";
import Navbar from "./components/navbar";

// page imports
import Home from "./components/pages/home";
import About from "./components/pages/about";
import History from "./components/pages/history";
import Sponsorship from "./components/pages/sponsorship";
import Robotics from "./components/pages/Robotics";
import Updates from "./components/pages/Updates";
import E404 from "./components/pages/Efour";
import Posts from "./components/pages/posts";



function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/about/history" exact component={History} />
            <Route path="/about/robotics" exact component={Robotics} />
            <Route path="/sponsorship" exact component={Sponsorship} />
            {/* <Route path="/updates" exact component={Updates}/> */}
            
            {/* {cardsKeys.map((year) =>
              cards[year].map((post) => (
                <Route
                  key={`${year}-${post.title}`}
                  path={`/updates/${year}/${post.title}`}
                  render={() => 
                  <Posts title={post.title} year={year} />
                }
                />
              ))
            )} */}
            <Route path="/error" exact component={E404} />
            {<Redirect to="/error" />}
            {/* <Route path="*" exact element=/> */}
          </Switch>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;