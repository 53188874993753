import { Helmet } from "react-helmet-async";
import TemplateLayout from "../main_layout";
import Button from "../button";
import TeamPhoto from "../../media/images/team-2021-crop.webp";
import CraigY from "../../media/images/craig_y.webp";
import DougB from "../../media/images/doug_b.webp";
import LynfieldLogo from "../../media/images/lynfield_college_logo.webp";

const who_are_we_para =
  "Lynfield College Robotics is a dedicated group of students based in Auckland, New Zealand, who share a passion for robotics and STEM education. We participate in the VEX Robotics competition and the VEX IQ Challenge, fielding multiple teams in each competition. At Lynfield College Robotics, we believe in empowering the next generation of innovators and problem solvers through hands-on learning experiences. We are committed to inspiring and nurturing the talents of young people who share our passion for robotics and STEM education.";
const what_we_do_para =
  "Throughout the year, we attend various scrimmages and competitions where we compete against other robotics teams from around Auckland, striving to achieve excellence in every match. Our commitment to innovation and teamwork has resulted in numerous awards and accolades, including tournament championships at both national and international events.";
const about_school_para =
  "Lynfield College is a renowned secondary school located in Auckland, New Zealand. Established in 1958, the school has a proud tradition of academic excellence and a commitment to providing a holistic education that prepares students for success in life. With a diverse student population of over 1700, Lynfield College offers a range of programs that cater to the individual needs and interests of every student. From academic subjects to sports, music, drama, and robotics, the school provides a rich and varied curriculum that enables students to develop their skills, talents, and passions.";
const c_yearbury_mail = "mailto:cyearbury@lynfield.ac.nz";
const what_we_do_para1 =
  "Lynfield College Robotics live within a small shed in our school. We survive on boxes upon boxes of pizza, and we thrive on the thrill that comes with all aspects of robotics; from tinkering with gears, bending metal, dodgy builds and breaking all the rules in the most law-abiding manner – we love what we do, and we do what we love. Robotics. For most of our free time you will see us in the shed working away at refining our craft in all areas of the robotics.";
const wwwlynfield =
  "https://www.lynfield.school.nz/Extra-Curricular/Robotics.html";
const learMoreLink = "/about/robotics";
function MentorCard(props) {
  return (
    <>
      <div className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100">
        <img
          className="object-cover w-full h-96 rounded-t-lg md:h-full md:w-48 md:rounded-none md:rounded-l-lg"
          src={props.imgSrc}
          alt={props.imgAlt}
        />
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-xl font-semibold text-gray-900">
            {props.name}
          </h5>
          <p className="mb-3 font-normal text-gray-700">{props.description}</p>
        </div>
      </div>
    </>
  );
}

export default function About() {
  return (
    <>
      <Helmet>
        <title>About | 2915</title>
        <meta
          name="description"
          content="Get to know the Lynfield College Robotics team and our mission. Learn about our dedicated members, our values, and our commitment to excellence in the field of robotics. Discover how we inspire the next generation of engineers, programmers, and robotics enthusiasts through our outreach programs and community events. Join us in our quest to push the boundaries of what's possible in robotics and beyond."
        />
      </Helmet>

      <TemplateLayout title="About Us" tagline="We ❤️ Robotics">
      <section>
        <h2 className="text-3xl font-bold">Who are we?</h2>
        <p className="my-5">{who_are_we_para}</p>
        <div className="flex justify-center">
          <div className="lg:w-1/2 rounded-md relative flex items-center md:max-w-screen md:h-auto md:justify-center">
            <img
              className=" h-full object-cover rounded-xl"
              src={TeamPhoto}
              alt=""
            />
            <div className="md:absolute inset-0 hover:bg-blue-400 transition-all ease-in mix-blend-multiply md:rounded-xl"></div>
          </div>
        </div>

        <div className="">
          <h1 className="text-3xl font-bold mt-16">What do we do?</h1>
          <p className="my-5">{what_we_do_para1}</p>
          <p className="my-5">{what_we_do_para}</p>
          <Button title="Learn More" href={learMoreLink} target="_self" />
        </div>
        </section>

        <section>
        <div className="">
          <h1 className="text-3xl font-bold mt-16 mb-5">Our Mentors</h1>
          <div className="grid gap-10 md:grid-flow-col">
            <MentorCard
              name="Craig Yearbury"
              imgSrc={CraigY}
              imgAlt="Craig Yearbury"
              description="Mr Yearbury is an exprined engineering teacher at Lynfield College, where he spends countless hours acting as the head of the 2915. His expertise and teaching combined with his good sense of humor have been immesnsely benficatal. Despite the demanding hours he dedicates towards the program, he continues to motivate and inspire his students."
            />
            <MentorCard
              name="Doug Bryan"
              imgSrc={DougB}
              imgAlt="Doug Bryan"
              description="Mr Bryan is a highly respected member of the 2915, bringing a wealth of knowledge and experience to the program. As a mentor and coach, he has played a crucial role in guiding the team towards success, both in competitions and in personal growth. His dedication and passion for robotics have been a source of inspiration for many students."
            />
          </div>
        </div>
        </section>

        <section>
        <div className="mt-16">
          <div className="grid md:grid-flow-col gap-6 items-center justify-items-center">
            <div>
              <h1 className="text-3xl font-bold mb-5">Lynfield College</h1>
              <p className="">{about_school_para}</p>
              <Button title="Learn More" href={wwwlynfield} target="_blank" />
            </div>
            <img
              src={LynfieldLogo}
              alt="Lynfield College Logo"
              className="hidden md:block md:rounded-full md:shadow"
            />
          </div>
        </div>
        </section>

        <section>
        <div className="">
          <h1 className="text-3xl font-bold mt-16 mb-5">Want to join us?</h1>
          <div className="mb-5">
            <p className="">
              If you are a current student attending Lynfield College feel free
              to talk to Mr Yearbury or approach anyone from our team and we’ll
              be sure to find a spot for you!
            </p>
            <br />
            <p>
              If you are a junior (Year 9), you will be placed in our VEX IQ
              Program which is designed to introduce you to the world of VEX and
              help you gain a grasp of core STEM concepts. On the latter, if you
              are a senior (Years 10-13), you will placed into one of our many
              senior VRC teams and will be assigned a role best suited to you
              individual interests and skills!{" "}
            </p>
          </div>
          <Button
            title="Email Mr. Yearbury"
            href={c_yearbury_mail}
            target="_blank"
          />
        </div>
        </section>
      </TemplateLayout>
    </>
  );
}
